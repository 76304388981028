import React, { useEffect, useMemo, useState } from 'react'
import { Switch, Route, Link } from 'react-router-dom'
import ImageMenu from './ImageMenu'
import Sequence from './Sequence'
import {
    ConfigContext,
    defaultConfigContextValue,
    AdminContext,
    defaultAdminContextValue,
    TypeAdminContext,
    NotificationsContext,
    defaultNotificationsContextValue,
    TypeNotificationsContext,
} from '../contexts'
import { TypeConfig, TypeConfigContext, TypeSequence, TypeNotification } from '../types'
import { FILE_URL, API_URL } from '../constants'
import Navigation from './Navigation'
import { useData, TypeUseData, useApi } from './hooks'
import AdminBar from './AdminBar'
import Notifications from './Notifications'
import Settings from './Settings'
import Import from './Import'
import Spinner from './Spinner'
import Footer from './Footer'
import Helmet from 'react-helmet'

type TypeAppProps = any

const App = React.memo(function App(props: TypeAppProps) {
    const [selected, setSelected] = useState(null)
    const [admin, setAdmin] = useState<any>({ ...defaultAdminContextValue, setSelected, selected })
    useEffect(() => {
        console.log('Checking', admin.edit, selected)
        !admin.edit && selected && setSelected(null)
    }, [admin.edit])
    {
        /* const [config, setConfig] = useState<TypeConfig>(props.configData?.data) */
    }
    const configData = useData<TypeConfig>({ src: 'config.json' })
    const config = configData.data
    const configApi = useApi()
    const saveConfig = (param: TypeConfigContext) => {
        const { setConfig, refresh, saveConfig, ...payload } = param
        return configApi.post('config', payload)
    }
    const configContextValue: TypeConfigContext = useMemo(() => {
        return config
            ? {
                  ...config,
                  sequence_array: config?.sequence_array.sort((a: TypeSequence, b: TypeSequence) => a.index - b.index),
                  setConfig: configData.setData,
                  refresh: configData.refresh,
                  saveConfig,
              }
            : defaultConfigContextValue
    }, [config])
    const [notification, setNotification] = useState<TypeNotificationsContext>(defaultNotificationsContextValue)
    const notificationData = useData<any>({ url: 'notifications' })
    useEffect(() => {
        notificationData?.data && setNotification((s: any) => ({ ...s, notification_array: notificationData.data, refresh: notificationData.refresh }))
    }, [notificationData?.data])
    const notificationContextValue: TypeNotificationsContext = useMemo(() => {
        return {
            ...notification,
            setNotificationArray: (notification_array: TypeNotification[]) => setNotification((s: any) => ({ ...s, notification_array })),
        }
    }, [notification])

    return (
        <ConfigContext.Provider value={configContextValue}>
            <Helmet>
                <title>{config?.title || 'Perfectsen.se App'}</title>
            </Helmet>
            <AdminContext.Provider value={useMemo(() => ({ ...admin, selected, setAdmin }), [admin, selected])}>
                <NotificationsContext.Provider value={notificationContextValue}>
                    {(config && (
                        <>
                            {(admin.enabled && <AdminBar />) || null}
                            <Switch>
                                <Route path={'/notifications'} exact={true}>
                                    <div className={admin.enabled ? ' bg-gray-100 edit-mode' : ''}>
                                        <Notifications />
                                    </div>
                                </Route>
                                <Route path={'/settings'} exact={true}>
                                    <div className={admin.enabled ? ' bg-gray-100 edit-mode' : ''}>
                                        <Settings />
                                    </div>
                                </Route>
                                <Route path={'/import'} exact={true}>
                                    <div className={admin.enabled ? ' bg-gray-100 edit-mode' : ''}>
                                        <Import />
                                    </div>
                                </Route>
                                <Route path="/" exact={true}>
                                    <div className={'w-screen h-screen' + (admin.enabled ? ' edit-mode' : '')}>
                                        <main className={'flex flex-col justify-center items-center w-screen h-screen'}>
                                            {(config?.root?.image_menu_id && <ImageMenu id={config?.root.image_menu_id} />) || null}
                                        </main>
                                    </div>
                                </Route>
                                <Route path={['/:sequence_group_id']}>
                                    <div className={'w-screen h-screen' + (admin.enabled ? ' edit-mode' : '')}>
                                        <main className={'flex flex-col justify-center items-center w-screen h-screen'}>
                                            <Route path={['/:sequence_group_id?/:sequence_id?/:image?/:zone?']}>
                                                <Sequence />
                                                <Navigation />
                                            </Route>
                                        </main>
                                    </div>
                                </Route>
                            </Switch>
                        </>
                    )) || (
                        <div className="flex items-center justify-center w-screen h-screen">
                            <Spinner className="mr-2" />
                        </div>
                    )}
                </NotificationsContext.Provider>
            </AdminContext.Provider>
        </ConfigContext.Provider>
    )
})

export default App
