import React from 'react'
import { useAdmin, useConfig } from './hooks'
import Image from './Image'

const DetailsPane = React.memo(function DetailsPane(props: any) {
    const { position, isOpen, setIsOpen, click_zone, tooltipPosition } = props
    const config = useConfig()
    const { enabled } = useAdmin()

    const image = (click_zone?.image_id && config.image_array.find((i) => i.id === click_zone.image_id)) || null

    let positionClassName
    switch (position) {
        case 'tooltip':
            positionClassName = 'details-pane-tooltip'
            break
        default:
            positionClassName = 'details-pane-left'
            break
    }

    // const style = { tooltipPosition }
    const { top, left, width, height } = tooltipPosition || {}
    const style: any = { position: 'absolute', top: top + height / 2 + 'px', left: left + width / 2 + 'px' }
    return (
        <div
            className={`details-pane z-20 ${positionClassName} ${isOpen ? '' : 'hidden'} ${
                enabled && config.details_pane_position !== 'tooltip' ? 'pt-12' : ''
            }`}
            style={style}
        >
            <div className="relative px-3 pt-3">
                <div onClick={() => setIsOpen(false)} className="absolute cursor-pointer top-0 right-0 pr-3 mr-3 mt-5">
                    <span className="text-center bg-white rounded-full p-1 w-6 h-6 block leading-none hover:bg-gray-200">&times;</span>
                </div>
                {(image && (
                    <>
                        <Image src={`image/${image.src}`} />
                    </>
                )) ||
                    null}
                {(position === 'tooltip' && <h4>{click_zone?.label}</h4>) || <h2>{click_zone?.label}</h2>}
                <p>{click_zone?.text}</p>
                {(click_zone?.file_id && <></>) || null}
            </div>
        </div>
    )
})

export default DetailsPane
