import React, { useMemo, useState, useEffect } from 'react'
import Page from './Page'
import useConfig from '../hooks/useConfig'
import Input, { Label } from './Input'
import Button from './Button'
import FileInput from './FileInput'
import Image, { Polaroid } from './Image'
import ImageStack from './ImageStack'
import Icon, { AddIcon, RemoveIcon } from './Icon'
import LazySVG from './LazySVG'
import { useInput, useApi, useSelect } from './hooks'
import { TypeConfigContext, TypeImageMenu, TypeImageMenuZone, TypeSequenceGroup, TypeSequence, TypeClickZone, TypeColor } from '../types'
import { THUMBNAILS_SIZE } from '../constants'
import { Link } from 'react-router-dom'
const shortid = require('shortid')

const getNewId = () => shortid.generate()

const thumbnail_placeholder_style = {
    width: THUMBNAILS_SIZE + 'px',
    height: THUMBNAILS_SIZE + 'px',
}

const ImageMenuInputGroup = React.memo(function ImageMenuInputGroup(props: any) {
    const { image_menu, setImageMenuArray, setImageMenuZoneArray, addImageMenuZone } = props
    const config = useConfig()
    // const srcInput = useInput({
    //     value: image_menu.src,
    //     onChange: (v: any) => setItemInArrayFactory(setImageMenuArray)((a: any) => a.id === image_menu.id)((im: TypeImageMenu) => ({ ...im, src: v })),
    // })
    const altInput = useInput({
        value: image_menu.alt,
        onChange: (v: any) => setItemInArrayFactory(setImageMenuArray)((a: any) => a.id === image_menu.id)((im: TypeImageMenu) => ({ ...im, alt: v })),
    })
    // const colorInput = useInput({
    //     value: image_menu.color,
    //     onChange: (v: any) => setItemInArrayFactory(setImageMenuArray)((a: any) => a.id === image_menu.id)((im: TypeImageMenu) => ({ ...im, color: v })),
    // })
    // const [image_src, setImageSrc] = useState('image-menu/' + image_menu.id + '.' + (image_menu.extension || ''))

    const image_menu_zone = config.image_menu_zone_array.filter((imz) => imz.image_menu_id === image_menu.id)

    return (
        <div className="flex mb-6">
            <div className="">
                <Polaroid
                    src={'image-menu/' + image_menu.id + '.' + (image_menu.extension || '') + '?' + image_menu.key}
                    resize={{ format: 'cover', width: THUMBNAILS_SIZE, height: THUMBNAILS_SIZE }}
                    className="border"
                />
                <FileInput
                    payload={{ image_menu_id: image_menu.id }}
                    onSuccess={(r: any) => {
                        config.refresh()
                    }}
                    className="inline-block mt-3"
                />
            </div>
            <div className="flex-grow ml-6">
                <Input label="Name" {...altInput.bind} className="mb-6" />
                {/* <Input type="color" label="Color" {...colorInput.bind} className="mt-6" /> */}
                {image_menu_zone.map((imz) => (
                    <ImageMenuZoneInput key={imz.id} image_menu_zone={imz} setImageMenuZoneArray={setImageMenuZoneArray} />
                ))}
                <Button type="button" size="xs" onClick={() => addImageMenuZone(image_menu.id)} className="mt-5">
                    Add a zone
                </Button>
            </div>
        </div>
    )
})

type TypeImageMenuZoneInputProps = { image_menu_zone: TypeImageMenuZone; setImageMenuZoneArray: Function }

const ImageMenuZoneInput = React.memo(function ImageMenuZoneInput(props: TypeImageMenuZoneInputProps) {
    const { image_menu_zone, setImageMenuZoneArray } = props
    const labelInput = useInput({
        value: image_menu_zone.label,
        onChange: (v: any) =>
            setItemInArrayFactory(setImageMenuZoneArray)((a: any) => a.id === image_menu_zone.id)((imz: TypeImageMenuZone) => ({ ...imz, label: v })),
    })
    const colorInput = useInput({
        value: image_menu_zone.color,
        onChange: (v: any) =>
            setItemInArrayFactory(setImageMenuZoneArray)((a: any) => a.id === image_menu_zone.id)((imz: TypeImageMenuZone) => ({ ...imz, color: v })),
    })
    return (
        <>
            <Input label="Name" {...labelInput.bind} className="mb-6" />
            <Input type="color" label="Color" {...colorInput.bind} className="mt-6" />
        </>
    )
})

type TypeSequenceGroupInputGroupProps = {
    sequence_group: TypeSequenceGroup
    addSequence: Function
    deleteSequence: Function
    deleteSequenceGroup: Function
    setSequenceGroupArray: Function
    setSequenceArray: Function
    setClickZoneArray: Function
    addClickZone: Function
    deleteClickZone: Function
    addImage: Function
    addFile: Function
}
const SequenceGroupInputGroup = React.memo(function SequenceGroupInputGroup(props: TypeSequenceGroupInputGroupProps) {
    const {
        sequence_group,
        addSequence,
        deleteSequence,
        deleteSequenceGroup,
        setSequenceGroupArray,
        setSequenceArray,
        setClickZoneArray,
        addClickZone,
        deleteClickZone,
        addImage,
        addFile,
    } = props
    const config = useConfig()
    const labelInput = useInput({
        value: props.sequence_group.label,
        onChange: (v: any) =>
            setItemInArrayFactory(setSequenceGroupArray)((a: any) => a.id === sequence_group.id)((sg: TypeSequenceGroup) => ({ ...sg, label: v })),
    })
    return (
        <>
            <div className="flex mb-6">
                <div className="flex-grow">
                    <Input label="Group Name" {...labelInput.bind} />
                </div>
                <RemoveIcon onClick={() => deleteSequenceGroup(sequence_group.id)} />
            </div>
            <ul className="">
                {config.sequence_array
                    .filter((s: TypeSequence) => s.sequence_group_id === sequence_group.id)
                    .map((s: TypeSequence) => (
                        <li key={s.id} className="mt-4">
                            <SequenceInputGroup
                                sequence={s}
                                sequence_group={sequence_group}
                                setSequence={setItemInArrayFactory(setSequenceArray)((a: any) => a.id === s.id)}
                                deleteSequence={deleteSequence}
                                setClickZoneArray={setClickZoneArray}
                                addClickZone={addClickZone}
                                deleteClickZone={deleteClickZone}
                                addImage={addImage}
                                addFile={addFile}
                            />
                        </li>
                    ))}
                <li>
                    <Button type="button" onClick={addSequence} className="mt-5">
                        Add Sequence
                    </Button>
                </li>
            </ul>
        </>
    )
})
type TypeSequenceInputGroupProps = {
    sequence: TypeSequence
    sequence_group: TypeSequenceGroup
    setSequence: Function
    deleteSequence: Function
    setClickZoneArray: Function
    addClickZone: Function
    deleteClickZone: Function
    addImage: Function
    addFile: Function
}
const SequenceInputGroup = React.memo(function SequenceInputGroup(props: TypeSequenceInputGroupProps) {
    const { sequence, setSequence, deleteSequence, setClickZoneArray, addClickZone, deleteClickZone, addImage, addFile, sequence_group } = props
    const config = useConfig()
    const labelInput = useInput({ value: sequence.label, onChange: (v: string) => setSequence((s: TypeSequence) => ({ ...s, label: v })) })
    return (
        <>
            <div className="flex mt-6">
                <div>
                    {(sequence.image_end && <ImageStack sequence={sequence} />) || null}
                    <div className="text-sm">
                        {(sequence.image_end !== void 0 && (
                            <div className="flex justify-between items-end mt-3">
                                {sequence.image_end + 1} image{sequence.image_end ? 's' : ''}
                                <FileInput multiple payload={{ sequence_id: sequence.id }} onSuccess={() => config.refresh()} className="inline-block mt-3" />
                            </div>
                        )) || (
                            <div style={thumbnail_placeholder_style} className={'flex flex-col items-center justify-center border'}>
                                No images
                                <FileInput multiple payload={{ sequence_id: sequence.id }} onSuccess={() => config.refresh()} className="inline-block mt-3" />
                            </div>
                        )}
                    </div>
                </div>
                <div className="flex-grow ml-6">
                    <Input
                        label={
                            <>
                                Sequence Name{' '}
                                <Link to={`/${sequence_group.id}/${sequence.id}`}>
                                    <Icon name="external-link-alt" className="cursor-pointer" />
                                </Link>
                            </>
                        }
                        {...labelInput.bind}
                    />
                    <ul>
                        {config.click_zone_array
                            .filter((cz) => cz.sequence_id === sequence.id)
                            .map((cz) => (
                                <li key={cz.id}>
                                    <ClickZoneInputGroup
                                        click_zone={cz}
                                        sequence_id={sequence.id}
                                        setClickZone={setItemInArrayFactory(setClickZoneArray)((a: any) => a.id === cz.id)}
                                        deleteClickZone={deleteClickZone}
                                        addImage={addImage}
                                        addFile={addFile}
                                    />
                                </li>
                            ))}
                        <li>
                            <Button type="button" size="xs" onClick={() => addClickZone(sequence.id)} className="mt-5">
                                Add a zone
                            </Button>
                        </li>
                    </ul>
                </div>
                <RemoveIcon onClick={() => deleteSequence(sequence.id)} />
            </div>
        </>
    )
})
type TypeClickZoneInputGroupProps = {
    click_zone: TypeClickZone
    sequence_id: string
    setClickZone: Function
    deleteClickZone: Function
    addImage: Function
    addFile: Function
}
const ClickZoneInputGroup = React.memo(function ClickZoneInputGroup(props: TypeClickZoneInputGroupProps) {
    const { click_zone, sequence_id, setClickZone, addImage, addFile } = props
    const { saveConfig, setConfig, ...config } = useConfig()
    const labelInput = useInput({ value: click_zone.label, onChange: (v: string) => setClickZone((s: TypeClickZone) => ({ ...s, label: v })) })
    const colorInput = useInput({ value: click_zone.color, onChange: (v: string) => setClickZone((s: TypeClickZone) => ({ ...s, color: v })) })
    const attachImage = () => {
        const image_id = getNewId()
        const newConfig = {
            ...config,
            click_zone_array: config.click_zone_array.map((cz) => (cz.id === click_zone.id ? { ...cz, image_id } : cz)),
            image_array: [...(config.image_array || []), { id: image_id }],
        }
        saveConfig(newConfig).then(() => setConfig(newConfig))
    }
    const attachFile = () => {
        const file_id = getNewId()
        const newConfig = {
            ...config,
            click_zone_array: config.click_zone_array.map((cz) => (cz.id === click_zone.id ? { ...cz, file_id } : cz)),
            file_array: [...(config.file_array || []), { id: file_id }],
        }
        saveConfig(newConfig).then(() => setConfig(newConfig))
    }

    return (
        <div className="mt-6 flex">
            <div>
                <div style={thumbnail_placeholder_style} className="relative flex">
                    <Image src={`sequence/${sequence_id}/${String(0).padStart(4, '0')}.jpg`} resize={{ width: 135, height: 135 }} className="opacity-25" />
                    <LazySVG src={`click_zone/${click_zone.id}/${String(0).padStart(4, '0')}.svg`} className="absolute top-0 left-0 click-zone-thumbnail" />
                </div>
                <FileInput multiple payload={{ click_zone_id: click_zone.id }} onSuccess={() => config.refresh()} className="inline-block mt-3" />
            </div>
            <div className="ml-6 flex-grow">
                <Input label="Zone Name" {...labelInput.bind} />
                <Input type="color" label="Color" {...colorInput.bind} className="mt-6" />
                {(click_zone.image_id && <>image</>) || (
                    <Button type="button" size="xs" onClick={attachImage} className="mt-5">
                        Attach an image
                    </Button>
                )}
                {(click_zone.file_id && <>file</>) || (
                    <Button type="button" size="xs" onClick={attachFile} className="mt-5">
                        Attach a file
                    </Button>
                )}
            </div>
        </div>
    )
})

type TypeSettingsProps = any
// setPartialStateFactory(config, 'sequence_group_array')
const setPartialStateFactory = (setState: Function) => (key: string) => (Fn: Function) => setState((s: any) => ({ ...s, [key]: Fn(s[key]) }))
const setItemInArrayFactory = (setState: Function) => (IdentityFn: Function) => (Fn: Function) =>
    setState((s: any[]): any[] =>
        s.reduce((acc: any[], i: any) => {
            acc.push(IdentityFn(i) ? Fn(i) : i)
            return acc
        }, [])
    )

const Settings = React.memo(function Settings(props: TypeSettingsProps) {
    const config = useConfig()
    const { setConfig, refresh, saveConfig }: TypeConfigContext = config
    const baseInput = useInput(config.base)
    const titleInput = useInput(config.title)
    const sequenceApi = useApi()
    const sequenceGroupApi = useApi()

    useEffect(() => {
        setConfig((c: TypeConfigContext) => ({ ...c, base: baseInput.value }))
    }, [baseInput.value])

    useEffect(() => {
        setConfig((c: TypeConfigContext) => ({ ...c, title: titleInput.value }))
    }, [titleInput.value])

    const addImageMenu = () => {
        const newConfig = { ...config, image_menu_array: [...config.image_menu_array, { id: getNewId() }] }
        saveConfig(newConfig).then(() => {
            setConfig(newConfig)
        })
    }
    const addImageMenuZone = (image_menu_id: string) => {
        const newConfig = {
            ...config,
            image_menu_zone_array: [...config.image_menu_zone_array, { id: getNewId(), image_menu_id }],
        }
        console.log('Adding click zone')
        saveConfig(newConfig).then(() => setConfig(newConfig))
    }
    const deleteImageMenu = (id: string) => {
        setConfig((s: TypeConfigContext) => ({ ...s, image_menu_array: s.image_menu_array.filter((im) => im.id !== id) }))
    }
    const addSequenceGroup = () => {
        const newConfig = { ...config, sequence_group_array: [...config.sequence_group_array, { id: getNewId() }] }
        saveConfig(newConfig).then(() => setConfig(newConfig))
    }
    const deleteSequenceGroup = (sequence_group_id: string) => {
        const newConfig = {
            ...config,
            sequence_group_array: config.sequence_group_array.filter((sg) => sg.id !== sequence_group_id),
            sequence_array: config.sequence_array.filter((s) => s.sequence_group_id !== sequence_group_id),
            click_zone_array: config.click_zone_array.filter(
                (cz) => config.sequence_array.find((seq) => seq.id === cz.sequence_id)?.sequence_group_id !== sequence_group_id
            ),
        }
        /* sequenceGroupApi.post('delete', { sequence_group_id }).then(() => {
            setConfig(newConfig)
        }) */
        saveConfig(newConfig).then(() => setConfig(newConfig))
    }
    const addSequence = (sequence_group_id: string) => {
        const newConfig = {
            ...config,
            sequence_array: [...config.sequence_array, { id: getNewId(), sequence_group_id }],
        }
        saveConfig(newConfig).then(() => setConfig(newConfig))
    }

    const deleteSequence = (sequence_id: string) => {
        const newConfig = {
            ...config,
            sequence_array: config.sequence_array.filter((seq) => seq.id !== sequence_id),
            click_zone_array: config.click_zone_array.filter((cz) => cz.sequence_id !== sequence_id),
        }
        /* sequenceApi.post('delete', { sequence_id }) */
        saveConfig(newConfig).then(() => setConfig(newConfig))
    }

    const addClickZone = (sequence_id: string) => {
        const newConfig = {
            ...config,
            click_zone_array: [...config.click_zone_array, { id: getNewId(), sequence_id }],
        }
        console.log('Adding click zone')
        saveConfig(newConfig).then(() => setConfig(newConfig))
    }

    const deleteClickZone = (click_zone_id: string) => {
        const newConfig = {
            ...config,
            click_zone_array: config.click_zone_array.filter((cz) => cz.id !== click_zone_id),
        }
    }

    const configApi = useApi()

    const save = (e?: any) => {
        e?.preventDefault?.()
        const { setConfig, refresh, saveConfig, ...c } = config
        saveConfig(c)
    }

    const root_option_array = useMemo(() => config.image_menu_array.map((im) => ({ image_menu_id: im.id, label: im.alt })), [config.image_menu_array])
    const rootInput = useSelect({
        value: root_option_array.find((o: any) => o.image_menu_id === config.root?.image_menu_id),
        onChange: (v: any) => setConfig((c: TypeConfigContext) => ({ ...c, root: v })),
    })

    const addColor = () => {
        const newConfig = {
            ...config,
            color_array: [...config.color_array, { id: getNewId() }],
        }
        saveConfig(newConfig).then(() => setConfig(newConfig))
    }

    const addImage = ({ image_id }: { image_id: string }) => {
        const newConfig = { ...config, image_array: [...(config.image_array || []), { id: image_id }] }
        saveConfig(newConfig).then(() => setConfig(newConfig))
    }
    const addFile = ({ file_id }: { file_id: string }) => {
        const newConfig = { ...config, file_array: [...(config.file_array || []), { id: file_id }] }
        saveConfig(newConfig).then(() => setConfig(newConfig))
    }

    return (
        <Page>
            <form className="w-8/12 mx-auto" onSubmit={save}>
                <h1 className="mb-5">Project Settings</h1>
                <h2 className="mb-6">General</h2>
                <Input type="text" label="Base" {...baseInput.bind} />
                <div className="mt-10">
                    <Input type="text" label="Project Title" {...titleInput.bind} />
                </div>
                <div className="mt-10">
                    <Input label="Homepage" type="select" options={root_option_array} {...rootInput.bind} />
                </div>

                <div className="mt-10 mb-6 flex justify-between items-center">
                    <h2>Image-menus</h2>
                    <Button type="button" onClick={addImageMenu} className="mt-5">
                        Add an image-menu
                    </Button>
                </div>
                <h2 className="mb-6">Colors</h2>
                <ul>
                    {config.color_array?.map((color) => (
                        <ThemeColorInput key={color.id} {...color} setConfig={setConfig} />
                    ))}
                </ul>
                <ul>
                    {config.image_menu_array.map((im: TypeImageMenu) => (
                        <li key={im.id}>
                            <ImageMenuInputGroup
                                image_menu={im}
                                setImageMenuArray={setPartialStateFactory(setConfig)('image_menu_array')}
                                setImageMenuZoneArray={setPartialStateFactory(setConfig)('image_menu_zone_array')}
                                addImageMenuZone={addImageMenuZone}
                            />
                        </li>
                    ))}
                </ul>
                <div className="mt-10 mb-6 flex justify-between items-center">
                    <h2>Sequence groups</h2>
                    <Button type="button" onClick={addSequenceGroup} className="mt-5">
                        Add Sequence Group
                    </Button>
                </div>
                <ul>
                    {config.sequence_group_array.map((sg: TypeSequenceGroup) => (
                        <li key={sg.id}>
                            <SequenceGroupInputGroup
                                sequence_group={sg}
                                addSequence={() => addSequence(sg.id)}
                                deleteSequence={deleteSequence}
                                deleteSequenceGroup={deleteSequenceGroup}
                                setSequenceGroupArray={setPartialStateFactory(setConfig)('sequence_group_array')}
                                setSequenceArray={setPartialStateFactory(setConfig)('sequence_array')}
                                setClickZoneArray={setPartialStateFactory(setConfig)('click_zone_array')}
                                addClickZone={addClickZone}
                                deleteClickZone={addClickZone}
                                addImage={addImage}
                                addFile={addFile}
                            />
                        </li>
                    ))}
                </ul>
                <div className="flex justify-end">
                    <Button onClick={save} color="primary" className="mt-5" isLoading={configApi.isLoading}>
                        {configApi.isLoading
                            ? 'Saving'
                            : (configApi.isSuccess && (
                                  <>
                                      <Icon name="check" className="mr-2" />
                                      Saved
                                  </>
                              )) ||
                              'Save'}
                    </Button>
                </div>
            </form>
        </Page>
    )
})

const ThemeColorInput = React.memo(function ThemeColorInput(props: any) {
    const { id, label, color, setConfig } = props

    return (
        <Input
            type="color"
            label={label}
            value={color}
            onChange={(e: any) => {
                const value = e.currentTarget.value
                setConfig((conf: TypeConfigContext) => ({
                    ...conf,
                    color_array: conf.color_array.map((c: TypeColor) => (c.id === id ? { ...c, color: value } : c)),
                }))
            }}
            className="mt-6"
        />
    )
})

export default Settings
