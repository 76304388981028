import { useState, useMemo, useEffect } from 'react'

export const useSelect = (initialValue?: any) => {
    let onChangeHook: any

    if (!Array.isArray(initialValue) && typeof initialValue === 'object' && initialValue !== null) {
        onChangeHook = initialValue.onChange
        initialValue = initialValue.value || ''
    } else {
        initialValue = initialValue || ''
    }

    const [value, setValue] = useState(initialValue)

    const onChange = (value_object: any) => {
        const value = typeof value_object.value !== 'undefined' ? value_object.value : typeof value_object.id !== 'undefined' ? value_object.id : value_object
        console.log('setting value to', value, value_object)
        onChangeHook?.(value)
        setValue(value)
    }

    return useMemo(
        () => ({
            value,
            onChange,
            bind: { value, onChange },
        }),
        [value]
    )
}

export default useSelect
