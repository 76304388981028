import React, { useMemo } from 'react'
import { useRouteMatch } from 'react-router-dom'
import { TypeSequence } from '../types'
import useConfig from './useConfig'

const useGetParams = () => {
    const config = useConfig()
    let {
        params: { sequence_group_id = config.sequence_group_array?.length ? config.sequence_group_array[0]?.id : undefined, image, sequence_id, zone },
    } = useRouteMatch() as any

    return useMemo(() => {
        const sequence_group = config.sequence_group_array.find((b) => b.id === sequence_group_id)
        const sequence_array: TypeSequence[] = sequence_group_id
            ? config.sequence_array.filter((l: TypeSequence) => l.sequence_group_id === sequence_group_id)
            : config.sequence_array

        sequence_id = sequence_id ?? sequence_array.find((l) => l.default)?.id ?? sequence_array[0]?.id
        const sequence = sequence_id && sequence_array.find((l) => l.id === sequence_id)

        const start = sequence?.image_start ?? sequence_group?.image_start ?? config.image_start
        const end = sequence?.image_end ?? sequence_group?.image_end ?? config.image_end

        const reverse_rotation = sequence?.reverse_rotation ?? sequence_group?.reverse_rotation ?? config.reverse_rotation
        const compass = sequence?.compass ?? sequence_group?.compass ?? config.compass
        const compass_offset = sequence?.compass_offset ?? sequence_group?.compass_offset ?? config.compass_offset

        const zone_stroke_color = zone?.zone_stroke_color ?? sequence?.zone_stroke_color ?? sequence_group?.zone_stroke_color ?? config.zone_stroke_color
        const zone_stroke_width = zone?.zone_stroke_width ?? sequence?.zone_stroke_width ?? sequence_group?.zone_stroke_width ?? config.zone_stroke_width
        const zone_color = zone?.zone_color ?? sequence?.zone_color ?? sequence_group?.zone_color ?? config.zone_color
        const zone_color_emphasis =
            zone?.zone_color_emphasis ?? sequence?.zone_color_emphasis ?? sequence_group?.zone_color_emphasis ?? config.zone_color_emphasis

        return {
            sequence_group_id,
            image,
            sequence_id,
            zone,
            sequence_group,
            sequence,
            sequence_array,
            start,
            end,
            reverse_rotation,
            compass,
            compass_offset,
            zone_stroke_color,
            zone_stroke_width,
            zone_color,
            zone_color_emphasis,
        }
    }, [config, sequence_group_id, image, sequence_id, zone])
}

export default useGetParams
